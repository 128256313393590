@import '../theme-bootstrap';

// iframe offers
.applied-offer-overlay {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 30px;
  text-align: center;
}

.applied-offer-overlay-container {
  #cboxClose {
    background-image: url('#{$base-theme-path}img/icons/src/close.svg');
    background-size: 33px 33px;
    background-position: center;
    &:hover {
      /* stylelint-disable-next-line declaration-no-important */
      background-position: center !important;
    }
  }
}
